import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { isAuthenticated } from '../guards/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: () => import('../views/HomePage.vue'),
    beforeEnter: () => isAuthenticated(),
  },
  {
    path: '/restaurants',
    component: () => import('../views/restaurants/ListRestaurants.vue'),
    beforeEnter: () => isAuthenticated()
  },
  {
    path: '/restaurants/:slug',
    component: () => import('../views/restaurants/IndexRestaurant.vue'),
    beforeEnter: () => isAuthenticated()
  },
  {
    path: '/restaurants/:slug/reservation',
    component: () => import('../views/restaurants/TableReservation.vue'),
    beforeEnter: () => isAuthenticated()
  },
  {
    path: '/restaurants/:slug/menu',
    component: () => import('../views/restaurants/MenuTabs.vue'),
    beforeEnter: () => isAuthenticated()
  },
  {
    path: '/bookings',
    component: () => import('../views/bookings/MyBookings.vue'),
    beforeEnter: () => isAuthenticated()
  },
  {
    path: '/bookings/:id',
    component: () => import('../views/bookings/EditBookings.vue'),
    beforeEnter: () => isAuthenticated()
  },
  {
    path: '/auth/login',
    component: () => import('../views/auth/AuthLogin.vue')
  },
  {
    path: '/profile/edit',
    component: () => import('../views/EditProfile.vue')
  },
  {
    path: '/auth/register',
    component: () => import('../views/auth/AuthRegister.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
