
import router from '../router/index';
import storageService from './../services/storage.service';

export const isAuthenticated = () => {

    /**
     * Static user check
     */
    const user = storageService.get('user');

    if ( !user ) {        
        getOuttaHere();
    }

    return true;
}

const getOuttaHere = () => {
    router.push('/auth/login');
    return false;
}