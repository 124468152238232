
import en from './lang/en.json';
import es from './lang/es.json';
import it from './lang/it.json';
import de from './lang/de.json';
import fr from './lang/fr.json';
import no from './lang/no.json';

import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: 'en',
  messages: {
    en, es, it, de, fr, no
  },
});

export {
  i18n
}