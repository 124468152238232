const storageService = {

  get(key:string) {
    const item = localStorage.getItem(key);

    if ( null == item ) return null;

    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  },
  
  set(key:string, obj:any) 
  {
    localStorage.setItem(key, JSON.stringify(obj));
  },

  remove(key:string)
  {
    localStorage.removeItem(key);
  }
}

export default storageService;