import axios from 'axios';
import { config } from '../env';
import storageService from './storage.service';
import store from '../stores/store';
import router from '../router/index';
import { languageService } from './language.service';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const handleAuthentication = () => {
    if ( storageService.get('token') ) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + storageService.get('token');
    }
};

const request = {
    
    baseUrl: config.apiURL,
    config: {},
    parameters: {},
    get(path, params = {}, config = {} )
    {
        handleAuthentication();
        
        this.parameters = {
            ...this.parameters,
            locale: languageService.get()
        };
        
        const request = axios.get(
            this.buildUrlQueryString(path, {...this.parameters,...params}),
            {
                ...this.config,
                ...config
            }
        );

        this.interceptResponse(request);

        return request;
    },
    post(path, params, config = {} )
    {
        handleAuthentication();
        
        this.parameters = {
            ...this.parameters,
            locale: languageService.get()
        }
        
        const request = axios.post(
            this.buildUrlQueryString(path, this.parameters),
            params,
            {
                ...this.config,
                ...config
            }
        );

        this.interceptResponse(request);

        return request;
    },
    buildUrlQueryString(path, args) {
        
        if ( /^https?:\/\//.test(path) ) return path;

        return this.baseUrl + path + ( args ? '?' + this.serialize( args ).join( '&' ) : '' )
    },
    serialize(params, parent = null) {

        const esc = encodeURIComponent;
        const query = [];

        let i;
        for (i in params) {
            const element = params[i];
            if ('object' == typeof element) {
                const qr = this.serialize(element, (parent ? parent + `[${esc(i)}]` : esc(i) ) );

                Object.keys(qr).map( (el:any) => {
                query.push(qr[el]);
                })
            } else {
                parent ? query.push(`${parent}[${esc(i)}]=${esc(element)}`) : query.push(`${esc(i)}=${esc(element)}`);
            }
        }

        return query;
    },
    interceptResponse(request)
    {
        request.catch(error => {
            console.log("Error", error);
            
            if ( error.response.status == 401 ) {
                this.get('auth/logout');
                
                storageService.remove('user');
                storageService.remove('token');

                store.dispatch('auth/logout');

                router.push('/auth/login');
            }
        })
    }
}

export { request };