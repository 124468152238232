import { request } from './request.service';
import storageService from './storage.service';
import store from './../stores/store';

const authService = {

    async login({ email, password })
    {
        const req = request.post('auth/login', {
            email,
            password
        })

        req.then(success => {
            this.storeUserData(success.data);
        })

        return req;
    },
    async register(params)
    {
        const req = request.post('auth/register', params);

        req.then(success => {
            this.storeUserData(success.data);
        })

        return req;
    },
    async updateUser(params)
    {
        const req = request.post('auth/update', params, { headers: { 'Content-Type': 'multipart/form-data' }});

        req.then(success => {
            this.storeUserData(success.data);
        })

        return req;
    },
    async fetchUser()
    {
        if ( !storageService.get('token') ) throw new Error('No user token available');

        return request.get('auth/user');
    },
    storeUserData({ token, user })
    {
        // localStorage
        storageService.set( 'user', user );
        if ( token ) {
            storageService.set( 'token', token );
        }

        store.dispatch('auth/login', user );
    },
    initializeUser()
    {
        /**
         * Get stored user if exists
         */
        const user = storageService.get('user');
        if ( !user ) return;

        /**
         * Initialize stored user into store
         */
        store.dispatch('auth/login', user);

        /**
         * get user from server
         */
        let req;
        try {
            req = this.fetchUser();
        } catch (e) {
            // token not stored. do nothing
        }

        req.then(success => {
            const params = {};
            
            if ( success.data['user'] ) {
                params['user'] = success.data['user'];
            }
            this.storeUserData(params);
        });
    },
    logout()
    {
        request.get('auth/logout');
        
        storageService.remove('user');
        storageService.remove('token');

        store.dispatch('auth/logout');
    }
}
export {
    authService
}