import storageService from "./storage.service";
import { i18n } from "@/i18n";

const languageService = {
    locale: storageService.get('locale') || i18n.global.locale,
    set(locale) {
        this.locale = locale;
        storageService.set('locale', locale);
        i18n.global.locale = locale;
    },
    get() {
        return this.locale;
    },
    list() {
        return {
            en: 'english',
            es: 'spanish',
            it: 'italian',
            de: 'deutsch',
            no: 'norwegian'
        };
    }
}

export {
    languageService
};