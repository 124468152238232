const state = {
  user: null
}

const getters = {
  isLoggedIn: state => !!state.user,
  getUser: state => state.user
}

const mutations = {
  setUser: (state, user) => {
    state.user = user
  },
  clearUser: state => {
    state.user = null
  }
}

const actions = {
  login: ({ commit }, user) => {
    commit('setUser', user)
  },
  logout: ({ commit }) => {
    commit('clearUser')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}